.css-o1d5fg-MuiPaper-root-MuiAppBar-root{
    position: fixed !important;
    z-index: 1 !important;
}
.navContent{
    color: #3F3F3F !important;
    padding: 6px 25px !important;
}
.navContent:active , .navContent:hover{
    color: #D9186A !important;  
}
a{
    text-decoration: none;
}
.css-1t6c9ts{ 
justify-content: center !important;
}
.css-ss28f8-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #fff !important;
    color: #D9186A !important;
}

.css-19r6kue-MuiContainer-root{
    width: 70% !important;
}
@media only screen and (max-width: 1024px) {

    .MuiButtonBase-root:focus{
        color: #D9186A !important;
    }
    .MuiButtonBase-root:active{
        color: #D9186A !important;
    }
    .css-1t6c9ts{
        justify-content: center !important;
        }
        .css-ss28f8-MuiButtonBase-root-MuiButton-root:hover{
            background-color: #fff !important;
            color: #D9186A !important;
        }
        .css-19r6kue-MuiContainer-root {
            margin-left: none !important;
            margin-right: none !important;
            padding-left: 1px !important;
            padding-right: 1px !important;
        }
        .css-ss28f8-MuiButtonBase-root-MuiButton-root{
            font-size: 0.5em !important;
        }
        .css-ss28f8-MuiButtonBase-root-MuiButton-root{
            /* padding: 2px 1px !important; */
        }
        .css-19r6kue-MuiContainer-root{
            width: 100% !important ;
        }
        .css-hip9hq-MuiPaper-root-MuiAppBar-root{
            background-color: #fff !important;
            box-shadow: none !important;
        }
        .css-1pz7awu-MuiButtonBase-root-MuiIconButton-root{
            color: #D9186A !important;
        }
        .css-o1d5fg-MuiPaper-root-MuiAppBar-root{
            position: fixed !important;
        }
        .css-1uwgr7b-MuiTypography-root{
            color: #3F3F3F;        
        }
        a{
            text-decoration: none !important;
        }
        .css-1uwgr7b-MuiTypography-root:hover ,.css-1uwgr7b-MuiTypography-root:focus {
            color: #D9186A ;       
        }
  }


