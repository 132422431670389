.logo{
    width: 55vw;
    height: auto;
    opacity: 0.4;
}

@media only screen and (max-width: 1024px) {
    .heroContentOne{
        font-size: 4em !important;
    }
    .heroContentTwo{
        font-size: 2em !important; 
    }
    .heroContainer{
        width: 100vw !important;
        height: 80vh !important;
        padding-top: 40px !important;
        display: flex !important;
        flex-direction: column-reverse !important;
        display: flex;
        flex-direction: column-reverse;
        /* margin-top: -96px; */
    }
    .heroContent{
        width: 90vw !important;
        /* align-items: center !important; */
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

    }
    .logoBox{
        width: 100vw !important;
    }

    .logo{
        width: 95vw;
    }
    

  }