.css-v6mo1y-MuiTimelineDot-root ,.css-1nqcc1s{
    background-color:#D9186A!important;
}

@media only screen and (max-width: 1024px) {
    .css-ghevlx-MuiTypography-root-MuiTimelineContent-root{
      font-size: 1em !important;
    }
    .css-tyxktk-MuiTypography-root{
        margin-top: 0 !important;
    }
    .contnetFont{
        font-size: 0.9rem !important;
    }
    .experienceBox{
        padding-top: 0px !important;
    }
  }

