@media only screen and (max-width: 1024px) {
    .aboutContent{
        width: 80% !important;
    }
    .title{
        font-size: 2em !important;
    }
    .aboutSubTitle{
        font-size: 1em !important;
    }
    .aboutContainer {
        padding-top: 3em !important;
    }
    .content{
        font-size: 1em !important;
 
    }
  }