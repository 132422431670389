  
  .container{
    display: flex;
    flex-wrap: wrap;
    max-width: 1100px;
 
   }
   .container .card{
   position: relative;
   width: 320px;
   height: 450px;
   /* background: #6883BA; */
   background: #F2E2E3;
   border-radius: 20px;
   overflow: hidden;
   margin-left: 2em;
   margin-bottom: 1em;
   
 }
 
 .container .card:before{
   content: '';
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: #D9186A;
   clip-path: circle(150px at 80% 20%);
   transition: 0.5s ease-in-out;
 }
 
 .container .card:hover:before{
   clip-path: circle(300px at 80% -20%);
 }
 
 /* .container .card:after{
   content: 'Nike';
   position: absolute;
   top: 30%;
   left: -20%;
   font-size: 12em;
   font-weight: 800;
   font-style: italic;
   color: rgba(255,255,25,0.05)
 } */
 
 .container .card .imgBx{
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
   /* z-index: 10000; */
   width: 100%;
   height: 220px;
   transition: 0.5s;
 }
 
 .container .card:hover .imgBx{
   top: 0%;
   transform: translateY(0%);
     
 }
 
 .container .card .imgBx img{
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) ;
   /* width: 270px; */
   width: 100px;
 }
 
 .container .card .contentBx{
   position: absolute;
   bottom: 0;
   width: 100%;
   height: 100px;
   text-align: center;
   transition: 1s;
   z-index: 10;
 }
 
 .container .card:hover .contentBx{
   height: 210px;
 }
 
 .container .card .contentBx h2{
   position: relative;
   font-weight: 600;
   letter-spacing: 1px;
   color: #3F3F3F;
   margin: 0;
 }
 
 
 
 
 /* .container .card .contentBx a{
   display: inline-block;
   padding: 10px 20px;
   background: #fff;
   border-radius: 4px;
   margin-top: 10px;
   text-decoration: none;
   font-weight: 600;
   color: #111;
   opacity: 0;
   transform: translateY(50px);
   transition: 0.5s;
   margin-top: 0;
 }
  */
 /* .container .card:hover .contentBx a{
   opacity: 1;
   transform: translateY(0px);
   transition-delay: 0.75s;
   
 } */
 
 

.container .card .contentBx .tag, .container .card .contentBx .des {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: 0.5s;opacity: 0;
    visibility: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .container .card:hover .contentBx .tag{
    opacity: 1;
    visibility: visible;
    transition-delay: 0.5s;
  }
  
  .container .card:hover .contentBx .des{
    opacity: 1;
    visibility: visible;
    transition-delay: 0.8s;
  }
  
  .container .card .contentBx .size h3, .container .card .contentBx .des h3{
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
  }
  
  .container .card .contentBx .tag {
    width: auto;
    height: 30px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    background: #fff;
    margin: 0 5px;
    transition: 0.5s;
    color: #3F3F3F;
    border-radius: 4px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
  /* .container .card .contentBx .tag span:hover{
    background: #9bdc28;
  }
  

.container .card .contentBx .des span{
    width: 20px;
    height: 20px;
    background: #ff0;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
   */

  
 .large{
width: 310px !important;
border-radius: 5px;
   }
 .notlarge{
width: 100px !important;
   }
/* sama 220 , loqean 100 */

/* 
   @media only screen and (max-width: 1024px) {

    .css-tyxktk-MuiTypography-root{
        font-size: 2em !important;
    }

    .container .card .contentBx h2{
      font-size: 1em !important;
    }

    .container .card .contentBx .tag{
      font-size: 12px !important;
    }
    .container .card{
      margin-bottom: 3em;
    }
  }

  @media only screen and (max-width: 820px) {
    .container  {
      justify-content: center;
    }
  } */



  @media screen and (min-width : 820px),screen and (max-width : 1000px){
    .container  {
      justify-content: center;
    }
  }
  @media screen and (max-width : 480px){
    .css-tyxktk-MuiTypography-root{
      font-size: 2em !important;
  }

  .container .card .contentBx h2{
    font-size: 1em !important;
  }

  .container .card .contentBx .tag{
    font-size: 12px !important;
  }
  .container .card{
    margin-bottom: 3em;
  }
  }
  

