.reactIcon{
    width: 3%;
    height: auto;
    margin-right: 1%;
}



@media only screen and (max-width: 1024px) {

    .reactIcon{
        width: 10%;
        height: auto;
        margin-right: 3%;
    }

    .css-ahj2mt-MuiTypography-root{
        /* font-size: 0.8em !important; */
    }
    .text{
        font-size: 0.5rem !important;
    }
  }

  /* @media screen and (min-width : 350px) and (max-width : 1200px) {
    .text{
        font-size: 1rem !important;
    }
} */