.mButton{
    background-color: #F2E2E3;
    width: 70px;
    height: 70px;
    justify-content: center;
    display: flex;
    border-radius: 490px;
    align-items: center;
    box-shadow: 0px 4px 10px #77737354;
}
.linkedinIcon{
    /* width: 20%; */
    height: 40%;
    content: url('../Assets/linkedin-v2.png');
    background-color: #F2E2E3;
    /* border: 2px solid #F2E2E3; */
    /* border-radius: 50%; */
    padding: 8px;
    border-radius: 10px;

}

.phoneIcon{
    /* width: 20%; */
    height: 40%;
    content: url('../Assets/phone-v2.png');
    background-color: #F2E2E3;
    /* border: 2px solid #F2E2E3; */
    /* border-radius: 50%; */
    padding: 8px;
    border-radius: 10px;
}

.emailIcon{
    /* width: 20%; */
    height: 40%;
    content: url('../Assets/email-v2.png');
    background-color: #F2E2E3;
    /* border: 2px solid #F2E2E3; */
    /* border-radius: 50%; */
    padding: 8px;
    border-radius: 10px;
}


.icon:hover{
    /* background-color: #fff; */
    /* border-radius: 20%; */
    /* width: 10%;
    height: 30%;
    padding: 8px; */
}

.pattren{
    /* content: url('../Assets/pattren.png'); */
    background-image: url('../Assets/pattren-v2.png');
    /* width: 100%;
    height: 100%; */
    /* opacity: 0.4; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    margin-right: 2px;
}
/* .card{
    border: 2px solid #f2e2e3;
    border-radius: 5px;
    box-shadow: 0px 1px #f3eaea6e;
    width: 100%;
}


.card:hover {
    background-color: #F2E2E3 ;
}  */
  .pattren::before{
    filter: opacity(0.4);
  }
  .qr{
    width: 50%;
    height: auto;
  }

  .medai{
    padding-top:30px !important;
}

  @media only screen and (max-width: 1024px) {

    .css-tyxktk-MuiTypography-root{
        font-size: 2em !important;
    }

    .css-5rvw4u{
        width: 90vw !important;
    }

    .css-73ttr9{
      font-size: 12px !important;
    }
    .css-zhhiv5, .css-ta0xl3{
        /* padding-left: 1em !important; */
    }
    .css-d1ztxm-MuiTypography-root{
        font-size: 1em!important; 
    }
    .qr{
        width: 100% !important;
    }
    .emailClass{
        /* width: 150px !important;
        word-break: break-all !important; */
    }
    .linkedinIcon , .phoneIcon , .emailIcon{
    /* width: 20%; */
    height: 40%;
    }
    .scan{
        font-size: 1rem !important;
    }
    .medai{
        width: 100%  !important;
        /* align-items: center; */
        /* padding-top:30px ; */
    }
    .mButton{
        width: 50px;
        height: 50px;
        display: flex;

    }
    
  }