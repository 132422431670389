  .container-skillbar {
    min-width: 100% ;
    padding-top: 30px;
    /* padding-bottom: 30px;
    padding-right: 2%;
    padding-left: 2%; */
    height: auto;
    overflow: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
  .skillbar {
    position: relative;
    /* display: block; */
    margin-bottom: 10px;
    width: 40% ;
    /* background: #efefef; */
    height: 70px;
    border-radius: 0px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -webkit-transition: 0.4s linear;
    -moz-transition: 0.4s linear;
    -ms-transition: 0.4s linear;
    -o-transition: 0.4s linear;
    transition: 0.4s linear;
    -webkit-transition-property: width, background-color;
    -moz-transition-property: width, background-color;
    -ms-transition-property: width, background-color;
    -o-transition-property: width, background-color;
    transition-property: width, background-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .skillbar-title {
    position: absolute;
    padding: 1em;
    /* top: 0; */
    /* left: 0; */
    width: 170px;
    font-weight: bold;
    font-size: 16px;
    color: #3F3F3F;
    /* background: #6adcfa; */
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    justify-content: center;

  }


  
  
  .content {
    margin-left: auto;
    margin-right: auto;
    /* max-width: 800px; */
  }
  
.Group1{
    width: 50%;
}
.Group2{
   width: 50%;
}

.part1{
    display: flex;
    flex-direction: column;
    align-items: center;

}

/* @media only screen and (max-width: 1024px) {
  .css-1g0mce8-MuiTypography-root{
    font-size: 2em !important;
  }

  .css-2nw1ef{
    width: 100% !important;
  }
  .css-cxbx72-MuiTypography-root{
    width: 50% !important;
    font-size: 12px !important;
  }
  .skillbar{
    width: 80% !important;
    height: 40px;
  }
  .skillbar-title{
    font-size: 10px;
    width: 115px;
  }
  .css-480n1o {
    border-right: 3px solid #d9186a !important;
  }
  .subTitle{
    width: 60% !important;
    font-size: 1rem !important;
  }

} */

/* @media only screen and (min-width: 768px) {
  .subTitle{
    width: 30% !important;
    font-size: 1rem !important;
  }
  .skillbar {
    width: 46% !important;
    height: 40px;
  }
} */





/* For iphone: */
/* @media screen and (max-width : 320px){
  *{
    background-color: #3F3F3F;
  }
}  */


/* For ipad: */
@media screen and (min-width : 350px) and (max-width : 1200px) {
  .subTitle{
    width: 30% !important;
    font-size: 1rem !important;
  }
  .skillbar {
    width: 76% !important;
    height: 60px;
  }
  /* .container-skillbar {
    width: 100% !important;
  } */
} 

/* For mobile : like samsung grand(480 * 800): */
@media screen and (max-width : 480px){
  .css-1g0mce8-MuiTypography-root{
    font-size: 2em !important;
  }

  .css-2nw1ef{
    width: 100% !important;
  }
  .css-cxbx72-MuiTypography-root{
    width: 50% !important;
    font-size: 12px !important;
  }
  .skillbar{
    width: 80% !important;
    height: 40px;
  }
  .skillbar-title{
    font-size: 10px;
    width: 115px;
  }
  .css-480n1o {
    border-right: 3px solid #d9186a !important;
  }
  .subTitle{
    width: 60% !important;
    font-size: 1rem !important;
  }
  .container-skillbar {
    width: 100% !important;
  }
}